import { useEffect, useState } from 'react';

type FetchFunction<T, P = void> = (param?: P) => Promise<T>;

function useFetch<T, P = void>(fetchFunction: FetchFunction<T, P>, param?: P) {
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchFunction(param);
                setData(result);
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [fetchFunction, param]);

    return { data, loading, error };
}

export default useFetch;
