import React from 'react'
import Index from '../components/Contact/Index'

const Contact = () => {
    return (
        <div>
            <Index />
        </div>
    )
}

export default Contact