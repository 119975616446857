import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { API_URL, uploadImage } from '../../utils/data';
interface EditFormProps {
    setShowEditForm: (show: boolean) => void;
    fields: { name: string; type: string; label: string }[];
    initialData: any;
    onSubmit: (data: any) => void;
}
const EditForm = ({ setShowEditForm, fields, initialData, onSubmit }: EditFormProps) => {
    const [formData, setFormData] = useState<any>(initialData);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const { t } = useTranslation()
    useEffect(() => {
        setFormData(initialData);
        if (initialData.image) {
            setImagePreview(initialData.image);
        }
    }, [initialData]);
    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.type === 'file') {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                setIsUploading(true);
                try {
                    // Upload the image first
                    const imageName = await uploadImage(file);
                    setFormData({
                        ...formData,
                        [e.target.name]: imageName,
                    });
                    setImagePreview(imageName);
                } catch (error) {
                    console.error('Image upload failed:', error);
                } finally {
                    setIsUploading(false);
                }
            }
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = () => {
        onSubmit(formData);
        setShowEditForm(false);
    };
    return (
        <div className='fixed xs:w-full md:w-4/6 lg:w-4/5 inset-0  bg-[#000000e3] flex-center '>
            <div className=' w-2/3 no-scroll bg-white overflow-y-auto max-h-[70vh]'>
                <h2 className='text-2xl  font-bold bg-primary w-full p-4'>{t("edit-item")}</h2>
                <form onSubmit={(e) => e.preventDefault()} className='p-5'>
                    {fields.map((field) => (
                        <div key={field.name} className="py-3 text-xl font-semibold">
                            <label>{field.label}</label>
                            {field.type === 'textarea' ? (
                                <textarea
                                    name={field.name}
                                    onChange={handleInputChange}
                                    value={formData[field.name] || ''}
                                    className="border max-h-24  p-2 w-full focus:outline-none"
                                />
                            ) : field.type === 'file' ? (
                                <>
                                    <input
                                        type={field.type}
                                        name={field.name}
                                        onChange={handleInputChange}
                                        className='border p-2 w-full focus:outline-none'
                                        disabled={isUploading} 
                                    />
                                    {isUploading && <p>{t('Uploading image...')}</p>}
                                    {imagePreview && (
                                        <div className='mt-4'>
                                            <img
                                                src={`${API_URL}/imgs/${imagePreview}`}
                                                alt='Preview'
                                                className='w-32 h-32 object-cover'
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <input
                                    type={field.type}
                                    name={field.name}
                                    onChange={handleInputChange}
                                    value={formData[field.name] || ''}
                                    className="border p-2 w-full focus:outline-none"
                                />
                            )}
                        </div>
                    ))}
                    <div className=' grid text-xl'>
                        <div className=' justify-self-end flex gap-5 '>
                            <button onClick={() => setShowEditForm(false)} className=' font-bold text-secondry'>{t("cancel")}</button>|
                            <button onClick={handleSubmit} className=' font-bold text-primary'>{t("edit")}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditForm