import React, { FormEvent, useEffect, useState } from 'react'
import Hero from '../components/About/Hero'
import Footer from '../components/common/Footer'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getData, handleAuth } from '../utils/data';
import Loading from '../components/ui/Loading';
const Login = () => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [heroData, setHeroData] = useState<any>(null);
    useEffect(() => {
        const getAboutData = () => {
            getData(i18n.language).then(response => {
                if (!response.data) return;
                const hero = response.data[1].homePageSections[0]
                setHeroData(hero)
            });
        };
        getAboutData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        const loginData = {
            "Phone": phone,
            "Password": password
        }
        e.preventDefault();
        handleAuth(loginData, 'Login successful', navigate);
    };
    if (!heroData) return <Loading />
    return (
        <div>
            {/* <Hero heroData={heroData} /> */}
            <div className=' flex flex-col justify-center py-10'>
                <div className="flex-center flex-col w-full ">
                    <p className='sm:text-3xl xs:text-2xl font-bold text-black'>{t("login-now")}</p>
                    <form onSubmit={handleLogin}>
                        <div className=' flex flex-col  gap-2 py-8'>
                            <label>{t('Phone')}</label>
                            <input
                                type="text"
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="border p-2 w-full focus:outline-none"
                            />
                            <label>{t('Password')}</label>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="border p-2 w-full focus:outline-none"
                            />
                        </div>
                        <input type="submit"
                            value={t("login")}
                            className=" w-full cursor-pointer text-xl mb-3 flex-center bg-primary border-[1px] rounded-lg border-none gap-1 py-3 text-white"
                        />
                    </form>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Login