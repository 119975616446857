
import { Link } from 'react-router-dom'
import useFetch from '../../custom hooks/UseFetch';
import { API_URL, fetchSiteInfo } from '../../utils/data';

const Logo = () => {
  const { data: productsData, loading } = useFetch<any>(fetchSiteInfo);
  const siteInfo=productsData?.data[0]
  if(loading) return null
  return (
    <Link to={"/"} className=' w-20'>
        <img className=' w-full' src={`${API_URL}/imgs/${siteInfo.image}`} alt='logo' loading='lazy'/>
    </Link>
  )
}

export default Logo