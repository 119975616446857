import React, { useState } from 'react'
import DashbordTitle from '../../components/ui/DashbordTitle'
import { useTranslation } from 'react-i18next';
import { handlePasswordUpdate } from '../../utils/data';
import toast from 'react-hot-toast';

const ChangePass = () => {

    const { t, i18n } = useTranslation();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleUpdatePassword = (e: any) => {
        e.preventDefault()
        if (password === confirmPassword) {
            handlePasswordUpdate(i18n.language, password, "Authentication/ChangePassword")
        }else{
            toast.error(t('password not match'));
        }
    };
    return (
        <div className='bg-gray-200 pb-8 px-6'>
            <div>
                <DashbordTitle title={t("change-pass")} add={false} />
                <div className='flex-center pt-10 text-xl'>
                    <form onSubmit={handleUpdatePassword} className=' w-1/2 flex flex-col gap-5'>
                        <div>
                            <label >{t("password")}</label>
                            <input type="password" name="pass" value={password} onChange={(e) => setPassword(e.target.value)}
                                required className="border p-2 w-full focus:outline-none"
                            />
                        </div>
                        <div>
                            <label >{t("confirm-password")}</label>
                            <input type="password" name="pass2" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                required className="border p-2 w-full focus:outline-none" />
                        </div>
                        <input type="submit"
                            value={t("submit")}
                            className=" w-full cursor-pointer text-xl mb-3 flex-center bg-primary border-[1px] rounded-lg border-none gap-1 py-3 text-white"
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePass