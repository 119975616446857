import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import "./i18n"
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import Home from './components/Home/Home';
import AboutLayout from './routes/About';
import Contact from './routes/Contact';
import Products from './routes/dashbord/Products';
import Brands from './routes/dashbord/Brands';
import ContactInfo from './routes/dashbord/ContactInfo';
import Vision from './routes/dashbord/Vision';
import Team from './routes/dashbord/Team';
import Goals from './routes/dashbord/Goals';
import Address from './routes/dashbord/Address';
import { StateContext } from './context/StateContext';
import ContentPage from './routes/dashbord/ContentPage';
import ProtectedRoute from './routes/privateRoute/ProtectedRoute';
import Login from './routes/Login';
import SiteInfo from './routes/dashbord/SiteInfo';
import News from './routes/News';
import BrandsLayout from './routes/BrandsLayout';
import NewsInfo from './routes/dashbord/NewsInfo';
import ChangePass from './routes/dashbord/ChangePass';
import { Toaster } from 'react-hot-toast';


const router = createBrowserRouter([
    {
        path: "/",
        caseSensitive:false,
        element: <App />,
        children: [
            { index: true, element: <Home /> },
            { path: "about", element: <AboutLayout /> },
            { path: "contact-us", element: <Contact /> },
            { path: "brands", element: <BrandsLayout /> },
            { path: "news", element: <News /> },
            { path: "login", element: <Login /> },
            {
                path: "/dashbord",
                element: <ProtectedRoute />,
                children: [
                    { index: true, element: <Products /> },
                    { path: "products", element: <Products /> },
                    { path: "brands", element: <Brands /> },
                    { path: "contact", element: <ContactInfo /> },
                    { path: "vision", element: <Vision /> },
                    { path: "team", element: <Team/> },
                    { path: "goals", element: <Goals/> },
                    { path: "address", element: <Address/> },
                    { path: "content-manage/:pageId", element: <ContentPage/> },
                    { path: "siteInfo", element: <SiteInfo/> },
                    { path: "newsInfo", element: <NewsInfo/> },
                    { path: "change-password", element: <ChangePass/> },
                ]
            }
        ]
    },
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <StateContext>
            <RouterProvider router={router}/>
            <Toaster />
        </StateContext>
    </React.StrictMode>
);