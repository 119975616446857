
import { useTranslation } from 'react-i18next';
import HitunAd from './HitunAd';
import { navlinks, navlinks_en } from '../../constant';
import { Link } from 'react-router-dom';
import CircleIcon from '../../icons/CircleIcon';
import MMtech from '../ui/MMtech';
import LanguageSwitcher from '../ui/LanguageSwitcher';
import useFetch from '../../custom hooks/UseFetch';
import { fetchAddress, fetchBrands, fetchProducts, fetchSiteInfo } from '../../utils/data';
import Logo from '../ui/Logo';
import Loading from '../ui/Loading';
import { BiLogoFacebook } from 'react-icons/bi';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const { data: productsData } = useFetch<any>(fetchSiteInfo);
    const { data: addressesData } = useFetch<any>(fetchAddress);
    const { data: productData1 } = useFetch<any>(fetchProducts);
    const { data: brandData1 } = useFetch<any>(fetchBrands);
    const NavbarLinks = i18n.language === "ar" ? navlinks : navlinks_en;
    const brands = brandData1?.data
    const products = productData1?.data
    const siteInfo = productsData?.data[0]
    const addresses = addressesData?.data
    if (!brands || !products || !siteInfo || !addresses) return <Loading />
    {
        document.head.title = siteInfo.name;
        return (
            <div className=' bg-primary text-white overflow-hidden'>
                <HitunAd />
                <div className=' flex justify-between gap-10 md:flex-row xs:flex-col padding-container max-container text-2xl'>
                    <div className='xs:pb-10 sm:pb-0'>
                        <Logo />
                        <p className='text-2xl pt-5 pb-2'>{siteInfo.description}</p>
                        <ul className=' text-xl flex flex-col py-3'>
                            {addresses.map((item: any, index: number) => (
                                <li key={index} className='flex items-center gap-1'>
                                    <h5 >{item.name}:</h5>
                                    <p>{item.location}</p>
                                </li>
                            ))}
                        </ul>
                        <div className=' text-xl flex gap-5  py-3'>
                            <div>
                                {siteInfo.facebook ?
                                    <Link to={siteInfo.facebook}>
                                        <p className='bg-white rounded-full text-secondry w-7 h-7 flex-center'><BiLogoFacebook /></p>
                                        <div className=' w-8'>
                                            <CircleIcon />
                                        </div>
                                    </Link> : null
                                }
                            </div>
                            <div>
                                {siteInfo.youtube ?
                                    <Link to={siteInfo.youtube}>
                                        <p className='bg-white rounded-full text-secondry w-7 h-7 flex-center'><FaYoutube /></p>
                                        <div className=' w-8'>
                                            <CircleIcon />
                                        </div>
                                    </Link> : null
                                }
                            </div>
                            <div>
                                {siteInfo.instagrham ?
                                    <Link to={siteInfo.instagrham}>
                                        <p className='bg-white rounded-full text-secondry w-7 h-7 flex-center'><FaInstagram /></p>
                                        <div className=' w-8'>
                                            <CircleIcon />
                                        </div>
                                    </Link> : null
                                }
                            </div>
                            <div>
                                {siteInfo.twitter ?
                                    <Link to={siteInfo.twitter}>
                                        <p className='bg-white rounded-full text-secondry w-7 h-7 flex-center'><FaTwitter /></p>
                                        <div className=' w-8'>
                                            <CircleIcon />
                                        </div>
                                    </Link> : null
                                }
                            </div>
                        </div>
                        <LanguageSwitcher />
                    </div>
                    <div className=' w-full grid gap-y-10 pb-10 grid-cols-3 xs:hidden sm:grid'>
                        <div className=' lg:ps-10 xs:ps-0'>
                            <h3 className=' font-bold text-3xl pb-7'>{t("sections")}</h3>
                            <ul className='flex gap-3 flex-col'>
                                {NavbarLinks.map((link, index) => (
                                    <li key={index}>
                                        <Link to={link.href} className=' w-full text-gray-100'>
                                            {link.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className=' font-bold text-3xl pb-7'>{t("categories")}</h3>
                            <ul className='flex gap-3 flex-col text-gray-100'>
                                {products.map((product: any, index: number) => (
                                    index < 5 ? <li className='' key={index}>{product.name}</li> : null
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className=' font-bold text-3xl pb-7'>{t("important-links")}</h3>
                            <ul className='flex gap-3 flex-col'>
                                {brands.map((brand: any, index: number) => (
                                    index < 5 ? <Link to={"/"} key={index} className=' text-gray-100'>{brand.name}</Link> : null
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <MMtech />
            </div>
        );
    }
}

export default Footer;