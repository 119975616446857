
import SectionText from '../ui/SectionText'
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import * as MdIcons from 'react-icons/md'; 
import * as FaIcons from 'react-icons/fa'; 
import * as TfiIcons from 'react-icons/tfi';

const AboutUs = ({aboutData}:any) => {


    if (!aboutData) return null; 

    return (
        <div className='main-prop overflow-hidden'>
            <div className='max-container padding-container'>
                <div className='md:w-1/2 xs:w-full'>
                    <SectionText number={'01'} title={aboutData.subTitle} text={aboutData.content} />
                    <Link data-aos="fade-left" data-aos-delay="300" to={aboutData.link} className='flex items-center text-2xl font-bold gap-2 text-primary'>
                        {aboutData.linkTitle}
                        <IoIosArrowBack />
                    </Link>
                </div>
                <div className='main-prop'>
                    <ul className='grid gap-y-14 gap-x-5 xs:grid-cols-2 md:grid-cols-4'>
                        {aboutData.homePageSectionProperties.map((item: any, index: number) => {
                            
                            const iconName: string = item.icon as string;
                            let IconComponent;

                            if (iconName.startsWith('Md')) {
                                IconComponent = MdIcons[iconName as keyof typeof MdIcons];
                            } else if (iconName.startsWith('Fa')) {
                                IconComponent = FaIcons[iconName as keyof typeof FaIcons];
                            }  else if (iconName.startsWith('Tfi')) {
                                IconComponent = TfiIcons[iconName as keyof typeof TfiIcons];
                            }

                            return (
                                <li className='relative flex-center flex-col rounded-3xl xs:py-4 md:py-12 text-white bg-primary' key={index}>
                                    <p data-aos="fade-up" data-aos-delay="100" className='xs:text-6xl md:text-8xl mb-3 font-extrabold'>{item.title}</p>
                                    <p data-aos="fade-up" data-aos-delay="200" className='xs:text-xl md:text-3xl'>{item.content}</p>
                                    <div data-aos="zoom-in" className='xs:text-2xl md:text-4xl absolute z-10 -top-10 bg-secondry rounded-full xs:p-4 md:p-5'>
                                        {IconComponent && <IconComponent size={25} />} 
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
