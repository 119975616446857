import React from 'react'
import { SectionTextProps } from '../../types'

const SectionText = ({ number, title, text }: SectionTextProps) => {
    return (
        <div className=''>
            <h1 data-aos="fade-left" className=' xs:text-6xl md:text-8xl font-extrabold text-secondry'>{number}</h1>
            <h1 data-aos="fade-left" data-aos-delay="100" className=' xs:text-6xl md:text-8xl pb-6 font-extrabold '>{title}</h1>
            <p data-aos="fade-left" data-aos-delay="200" className=' text-2xl pb-8'>{text}</p>
        </div>
    )
}

export default SectionText