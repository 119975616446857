import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie'; 

import ar_translation from "./locales/ar/translation.json";
import en_translation from "./locales/en/translation.json";

const resources = {
    ar: {
        translation: ar_translation,
    },
    en: {
        translation: en_translation,
    },
};
const language = Cookies.get('i18next') || 'ar';

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: language, // Set the language from the cookie
        fallbackLng: 'ar',
        interpolation: {
            escapeValue: false,
        }
    });

// Save the language in cookies whenever it changes
i18n.on('languageChanged', (lng) => {
    Cookies.set('i18next', lng, { expires: 7 }); // Set a cookie to expire in 7 days
    console.log('language changed to:', lng);
});

export default i18n;
