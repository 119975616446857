import Home from "../components/News/Home"


const News = () => {
  return (
    <div>
        <Home/>
    </div>
  )
}

export default News