import { API_URL } from "../../utils/data";


const HitunTeam = ({ hitunTeam }: any) => {
    if (!hitunTeam) return null;
    return (
        <div className='main-prop padding-container max-container flex-center flex-col'>
            <h2 className='xs:text-6xl pb-12 font-extrabold'>{hitunTeam.subTitle}</h2>
            <ul className='  padding-container grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-10'>
                {hitunTeam.homePageSectionProperties.map((person: any, index: number) => (
                    <li key={index} className=' hitun-team rounded-xl flex-center flex-col py-16 px-5'>
                        <div className='w-5/6'>
                            <img className='w-full' src={`${API_URL}/imgs/${person.image}`} alt="person" loading='lazy' />
                        </div>
                        <h3 className=' text-4xl font-semibold text-center text-nowrap pb-5'>{person.title}</h3>
                        <p className=' text-3xl text-primary'>{person.content}</p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default HitunTeam