import SectionText from '../ui/SectionText'
import MainLink from '../ui/MainLink';
import Map from '../ui/Map';
import * as MdIcons from 'react-icons/md'; 
import * as FaIcons from 'react-icons/fa'; 
const ContactUs = ({contactData}:any) => {
    if (!contactData) return null;
    return (
        <div className='main-prop overflow-hidden'>
            <div className=' max-container padding-container'>
                <div>
                    <SectionText number={'03'} title={contactData.subTitle} text={contactData.content} />
                    <div data-aos="fade-left" data-aos-delay="300">
                        <MainLink title={contactData.linkTitle} href={contactData.link} />
                    </div>
                </div>
                <div className='my-10'>
                    <Map />
                </div>
                <ul className=' flex justify-between gap-5 xs:flex-col md:flex-row py-6'>
                    {contactData.homePageSectionProperties.map((item: any, index: number) => {
                        const iconName: string = item.icon as string;
                        let IconComponent;

                        if (iconName.startsWith('Md')) {
                            IconComponent = MdIcons[iconName as keyof typeof MdIcons];
                        } else if (iconName.startsWith('Fa')) {
                            IconComponent = FaIcons[iconName as keyof typeof FaIcons];
                        }
                        return(
                        <li data-aos="fade-up" data-aos-delay={`${index + 1}00`} key={index} className='flex items-center gap-3'>
                            <div className=' flex-center flex-col'>
                                <p className=' contact-icon text-primary text-2xl'>{IconComponent && <IconComponent size={20} />} </p>
                                <div className='w-10'>
                                    <img src="/asset/icon.webp" alt="icon" />
                                </div>
                            </div>
                            <div>
                                <h5 className='text-2xl font-semibold'>{item.title}</h5>
                                <p className='text-lg'>{item.content}</p>
                            </div>
                        </li>
                        );
                    })}
                </ul>
            </div >
        </div >
    )
}

export default ContactUs