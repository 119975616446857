import React, { createContext, useContext, useState } from "react";

export interface GlobalStateContext {
    showdeletePopup: boolean
    showAddForm: boolean
    showEditForm: boolean
    setShowdeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAddForm: React.Dispatch<React.SetStateAction<boolean>>;
    setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
}
const Context = createContext<GlobalStateContext | null>(null);
interface StateContextProps {
    children: React.ReactNode;
}
export const StateContext = ({ children }: Readonly<StateContextProps>) => {
    const [showdeletePopup, setShowdeletePopup] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    return (
        <Context.Provider value={{
            showEditForm,
            setShowEditForm,
            showAddForm,
            setShowAddForm,
            showdeletePopup,
            setShowdeletePopup
        }}>
            {children}
        </Context.Provider>
    );
}
export const useStateContext = () => useContext(Context);