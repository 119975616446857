import AboutUs from "./AboutUs"
import Brands from "./Brands"
import ContactUs from "./ContactUs"
import Footer from "../common/Footer"
import Hero from "./Hero"
import Products from "./Products"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { getData } from "../../utils/data"
import Loading from "../ui/Loading"
import toast, { Toaster } from "react-hot-toast"


const Home = () => {
  const { i18n } = useTranslation();
  const [aboutData, setAboutData] = useState<any>(null);
  const [brandsData, setBrandsData] = useState<any>(null);
  const [contactData, setContactData] = useState<any>(null);
  const [heroData, setHeroData] = useState<any>(null);
  const [productsData, setProductsData] = useState<any>(null);
  useEffect(() => {
    const getAboutData = () => {

      getData(i18n.language).then(response => {
        console.log('home', response);
        if (!response.data) {
          toast.error("خطأ في تحميل الصفحة");
          return;
        }
        var homePageSelection = response.data.filter((item: any) => item.number == 1);
        if (homePageSelection.length === 0) {
          toast.error("خطأ في تحميل الصفحة");
          return;
        }
        var homePage = homePageSelection[0];
        const hero = homePage.homePageSections[0]
        const brands = homePage.homePageSections[1];
        const about = homePage.homePageSections[2];
        const products = homePage.homePageSections[3];
        const contact = homePage.homePageSections[4];
        setAboutData(about);
        setBrandsData(brands);
        setContactData(contact);
        setHeroData(hero);
        setProductsData(products);
      });
    };
    getAboutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!heroData) {
    return <Loading />;
  }
  return (
    <div>
      <Hero heroData={heroData} />
      <Brands brandsData={brandsData} />
      <Products productsData={productsData} />
      <AboutUs aboutData={aboutData} />
      <ContactUs contactData={contactData} />
      <Footer />
    </div>
  )
}

export default Home