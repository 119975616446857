import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API_URL, getPagesContent, handleUpdateSection } from '../../utils/data';
import DashbordTitle from '../../components/ui/DashbordTitle';
import Table from '../../components/common/Table';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import EditForm from '../../components/dashbord/EditForm';
import { useTranslation } from 'react-i18next';

const ContentPage = () => {
    const {t,i18n}=useTranslation()
    const { pageId } = useParams();
    const [pageContent, setPageContent] = useState([]);
    const { showEditForm, setShowEditForm } = useStateContext() as GlobalStateContext;
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    useEffect(() => {
        if (pageId) {
            const getPages = () => {
                getPagesContent(i18n.language, pageId).then(response => {
                    // title = response.data[0].mainTitle;
                    // console.log(response.data);
                    setPageContent(response.data);
                });
            }
            getPages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageId,setPageContent])
    const handleEditProduct = (data: any) => {
        if (selectedItem) {
            handleUpdateSection(i18n.language, data)
        }

    };
    return (
        <div className=' bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={new URLSearchParams(window.location.search).get('name') ?? ""} add={false}/>
            {pageContent.length > 0 && (
                pageContent.map((item: any, index) => (
                    <div key={index} >
                        <div className='py-8'>
                            <h3 className='font-bold text-2xl'>{item.subTitle || ''}</h3>
                            <Table
                                headers={[t("element"), t("details")]}
                                data={Object.entries(item)}
                                renderRow={([key, value]: [string, any]) => (
                                    key !== "homePageId" && key !== "sectionMode" && key !== "id" &&
                                    key !== "createDate" && key !== "createdByName" && key !== "mainTitle" &&
                                    (
                                        <>
                                            <td className='font-bold'>{t(key)}</td>
                                            <div className='text-darkColor'>{
                                                key === "image" && value ? (
                                                    <td>
                                                        <div className='w-20 h-20 flex-center'>
                                                            <img src={`${API_URL}/imgs/${value}`} className='w-full' alt="no-image" />
                                                        </div>
                                                    </td>
                                                ) : (
                                                    <td>{typeof value === 'string' && value.length > 0 ? value : t("no-data-available")}</td>
                                                )
                                            }
                                            </div>
                                        </>
                                    )
                                )}
                            />
                            <div className='grid pt-5'>
                                <button onClick={() => {
                                    setSelectedItem(item);
                                    setShowEditForm(true);
                                }} className='bg-green-600 justify-self-end text-xl rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                            </div>
                            {showEditForm && selectedItem && <EditForm
                                setShowEditForm={setShowEditForm}
                                fields={[
                                    { name: 'subTitle', type: 'text', label: t("subTitle") },
                                    { name: 'content', type: 'textarea', label: t("content") },
                                    { name: 'link', type: 'text', label: t("link") },
                                    { name: 'linkTitle', type: 'text', label: t("link-title") },
                                    { name: 'image', type: 'file', label: t("image") }
                                ]}
                                initialData={selectedItem}
                                onSubmit={handleEditProduct}
                            />}
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}

export default ContentPage