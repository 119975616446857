import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Table from '../../components/common/Table';
import useFetch from '../../custom hooks/UseFetch';
import { fetchAddress, handleAddItem, handleUpdateItem } from '../../utils/data';
import DeletePopUp from '../../components/dashbord/DeletePopUp';
import Loading from '../../components/ui/Loading';
import AddForm from '../../components/dashbord/AddForm';
import EditForm from '../../components/dashbord/EditForm';
import AddButton from '../../components/ui/AddButton';
import DashbordTitle from '../../components/ui/DashbordTitle';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';

const Address = () => {
    const { t,i18n } = useTranslation();
    const [selectedAddress, setSelectedAddress] = useState<any>(null);
    const {showAddForm,showEditForm ,showdeletePopup, setShowAddForm,setShowEditForm,setShowdeletePopup} = useStateContext() as GlobalStateContext;

    const { data: addressesData, loading } = useFetch<any>(fetchAddress);
    const addresses=addressesData?.data
    const handleAdd = (data: any) => {
        handleAddItem(i18n.language, "AddressManagement", data) 
    };

    const handleEdit = (data: any) => {
        if(selectedAddress){
            handleUpdateItem(i18n.language,data,"AddressManagement")
        }
        
    };
    if (loading) return <Loading/>
    return (
        <div className='bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={t("control-address")} add={true} />
            <Table
                headers={[t("address"), t("details"), t("actions")]}
                data={addresses || []}
                renderRow={(address: any) => (
                    <>
                        <td className='font-bold'>{address.name}</td>
                        <td className='text-darkColor'>{address.location}</td>
                        <td>
                        <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedAddress(address);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                                <button onClick={() => {
                                    setSelectedAddress(address);
                                    setShowdeletePopup(true)
                                }
                                } className='bg-red-600 rounded-lg py-1 px-3 text-white'>{t("delete")}</button>
                            </div>
                        </td>
                    </>
                )}
            />
            <div className='grid py-8'>
            <AddButton setShowAddForm={setShowAddForm}/>
            </div>
            {showdeletePopup && <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={"AddressManagement/Delete"} id={selectedAddress.id} />}
            {showAddForm && <AddForm
                setShowAddForm={setShowAddForm}
                fields={[
                    { name: 'name', type: 'text', label: t("address") },
                    { name: 'location', type: 'textarea', label: t('details') }
                ]}
                onSubmit={handleAdd}
            />}
            {showEditForm && selectedAddress&& <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'name', type: 'text', label: t("address") },
                    { name: 'location', type: 'textarea', label: t('details') }
                ]}
                initialData={selectedAddress}
                onSubmit={handleEdit}
            />}
        </div>
    );
}


export default Address