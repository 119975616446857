import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import { fetchContact, handleUpdateItem } from '../../utils/data';
import Table from '../../components/common/Table';
import Loading from '../../components/ui/Loading';
import EditForm from '../../components/dashbord/EditForm';
import DashbordTitle from '../../components/ui/DashbordTitle';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';

const ContactInfo = () => {
    const { t,i18n } = useTranslation();
    const {showEditForm ,setShowEditForm} = useStateContext() as GlobalStateContext;
    const [selectedContact, setSelectedContact] = useState<any>(null);
    const { data: contactData,loading} = useFetch<any>(fetchContact);
    const contact=contactData?.data
    const handleEditContact = (data: any) => {
        if(selectedContact){
            handleUpdateItem(i18n.language,data,"HomePageManagement/Update/PagesSectionProperty")
        }

    };
    if(loading) return <Loading/>
    return (
        <div className=' pb-8 px-6'>
            <DashbordTitle title={t("control-contact")} add={false}/>
            <Table
                headers={[t("contact-way"), t("details"), t("actions")]}
                data={contact || []}
                renderRow={(contactItem: any) => (
                    <>
                        <td className=' font-bold'>{contactItem.title}</td>
                        <td className=' text-darkColor'>{contactItem.content}</td>
                        <td>
                        <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedContact(contactItem);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                            </div>
                        </td>
                    </>
                )}
            />
            {showEditForm && selectedContact && <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'title', type: 'text', label: t("contact-way") },
                    { name: 'content', type: 'textarea', label: t('details') }
                ]}
                initialData={selectedContact} // تمرير البيانات الحالية للمنتج المختار
                onSubmit={handleEditContact}
            />}
        </div>
    )

}

export default ContactInfo