import { useState, useEffect } from "react";
import { API_URL, getData } from "../../utils/data";

const Map = () => {
    const [contactData, setContactData] = useState<any>(null);
    useEffect(() => {
        const getContactData = () => {
            getData("ar").then(response => {
                const contact = response.data[1].homePageSections[4];
                setContactData(contact);
            });
        };
        getContactData();
    }, []);
    return (
        <div data-aos="fade-up" className='h-96 rounded-5xl overflow-hidden'>
            <a
                href="https://goo.gl/maps/fQWk5NFenviaCr1w5"
                target="_blank" rel="noreferrer" 
                className="w-full h-full "
            >
                <img
                    alt="تواصل معنا" 
                    className="w-full h-full rounded-5xl overflow-hidden object-cover image-link"
                    src={`${API_URL}/imgs/${contactData?.image}`}
                    loading="lazy"
                />
            </a>
        </div>
    );
};

export default Map;
