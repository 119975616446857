
import MainLink from '../ui/MainLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { API_URL, fetchBrands } from '../../utils/data';
import useFetch from '../../custom hooks/UseFetch';
import Loading from '../ui/Loading';

const Brands = ({brandsData}:any) => {
    const { data: brandData1, loading } = useFetch<any>(fetchBrands);
    const brands=brandData1?.data
    if (loading) return <Loading/>
    if (!brandsData) return null;
    return (
        <div className='main-prop overflow-hidden'>
            <div className='max-container padding-container  flex-center flex-col'>
                <h2 data-aos="fade-down" className=' font-extrabold xs:text-3xl md:text-4xl pb-16 ' >{brandsData.mainTitle}</h2>
                <div className='w-full relative'>
                    <Swiper
                        slidesPerView={5}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Autoplay]}>
                                {brands.map((brand:any, index:number) => (
                                    <SwiperSlide key={index}>
                                        <div className='flex-center py-5' >
                                            <div className=' w-36 h-36 rounded-full overflow-hidden brand-shadow flex-center'>
                                                <img className='w-full' src={`${API_URL}/imgs/${brand.image}`} alt="product-brand" />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                           
                    </Swiper>
                    <div className="swiper-button-next custom-swiper-button"></div>
                    <div className="swiper-button-prev  custom-swiper-button"></div>
                </div>
                <div data-aos="fade-up" className=' pt-16'>
                    <MainLink title={brandsData.linkTitle} href={brandsData.link} />
                </div>
            </div>
        </div>
    )
}

export default Brands