import React from 'react'
import Footer from '../common/Footer'
import Hero from './Hero'
import useFetch from '../../custom hooks/UseFetch';
import { API_URL, fetchBrands } from '../../utils/data';
import Loading from '../ui/Loading';

const Home = () => {
    const { data: brandData1, loading } = useFetch<any>(fetchBrands);
    const brands = brandData1?.data
    if (loading) return <Loading />
    return (
        <div>
            <Hero />
            <div className='main-prop padding-container'>
            <div className='grid lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-2'>
                {brands.map((brand: any, index: number) => (
                    <div key={index}>
                        <div className='flex-center py-5' >
                            <div className=' w-36 h-36 rounded-full overflow-hidden brand-shadow flex-center'>
                                <img className='w-full' src={`${API_URL}/imgs/${brand.image}`} alt="product-brand" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home