import { FaFacebook, FaGlobe, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
export const socialMediaLinksMMtech = [
    { name: "Facebook", href: "https://www.facebook.com/mmtechly", icon: <FaFacebook /> },
    { name: "WhatsApp", href: "https://wa.me/218911919187", icon: <FaWhatsapp /> },
    { name: "Website", href: "http://mmtech.ly/", icon: <FaGlobe /> },
    { name: "LinkedIn", href: "https://www.linkedin.com/company/mmtechly?trk=organization_guest_main-feed-card_reshare-text&originalSubdomain=ly", icon: <FaLinkedin /> }
];
const MMtech = () => {
    return (
        <div className='border-t-[1px] border-gray-300 py-5'>
            <div className='padding-container max-container flex-between xs:flex-col md:flex-row gap-8 '>
                <div className='text-2xl xs:text-center md:text-start'>جميع الحقوق محفوظة لشركة المنظومة المتجددة لتقنية المعلومات بنغازي -ليبيا &copy;</div>
                <ul className='flex gap-5 text-2xl'>
                    {
                        socialMediaLinksMMtech.map((item, index) => (
                            <li key={index}>
                                <Link to={item.href}>{item.icon}</Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default MMtech