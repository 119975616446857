import React, { useState } from 'react'
import { API_URL, fetchBlog } from '../../utils/data';
import Hero from './Hero';
import Footer from '../common/Footer';
import useFetch from '../../custom hooks/UseFetch';
import Loading from '../ui/Loading';
import { useTranslation } from 'react-i18next';
import NewsPopUp from './NewsPopUp';

const Home = () => {
    const { t } = useTranslation();
    const [selectedBlog, setSelectedBlog] = useState<any>(null);
    const { data: blogData } = useFetch<any>(fetchBlog);

    const blogs = blogData?.data;
    if (!blogs) return <Loading />

    return (
        <div>
            <Hero />
            <div className='main-prop padding-container'>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-10'>
                    {blogs?.map((blog: any, index: number) => (
                        <div className='brand-shadow p-6 rounded-md' key={index}>
                            <div className='w-60 h-60 overflow-hidden flex-center'>
                                <img className='w-full' src={`${API_URL}/imgs/${blog.image}`} alt="product-brand" />
                            </div>
                            <h2 className='text-2xl font-bold'>{blog.title}</h2>
                            <p className='text-lg text-darkColor'>
                                {blog.description.length > 100
                                    ? `${blog.description.slice(0, 100)}...`
                                    : blog.description}
                            </p>
                            <button
                                onClick={() => setSelectedBlog(blog)} 
                                className='mt-2 text-primary text-xl font-semibold flex-center'>
                                {t("show-more")} &gt;
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {selectedBlog && (
                <NewsPopUp
                    setShowNews={() => setSelectedBlog(null)} 
                    blog={selectedBlog} 
                />
            )}

            <Footer />
        </div>
    )
}

export default Home;
