import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import { fetchProducts, handleAddItem, handleUpdateItem } from '../../utils/data';
import { Product } from '../../types';
import Table from '../../components/common/Table';
import DeletePopUp from '../../components/dashbord/DeletePopUp';
import AddForm from '../../components/dashbord/AddForm';
import EditForm from '../../components/dashbord/EditForm';
import Loading from '../../components/ui/Loading';
import AddButton from '../../components/ui/AddButton';
import DashbordTitle from '../../components/ui/DashbordTitle';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';

import { API_URL } from '../../utils/data';

const Products = () => {
    const { t, i18n } = useTranslation();
    const { showAddForm, showEditForm, showdeletePopup, setShowAddForm, setShowEditForm, setShowdeletePopup } = useStateContext() as GlobalStateContext;
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const { data: productsData, loading, } = useFetch<any>(fetchProducts);
    const products = productsData?.data
    console.log(products)

    const handleAddProduct = (data: any) => {
        handleAddItem(i18n.language, "ProductManagement", data)
        console.log("New Product Data:", data);
    };

    const handleEditProduct = (data: any) => {
        if (selectedProduct) {
            handleUpdateItem(i18n.language, data, "ProductManagement")

        }

    };
    if (loading) return <Loading />

    return (
        <div className='bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={t("control-product")} add={true} />
            <Table
                headers={[t("product-name"), t("details"), t("brands"), t("actions")]}
                data={products || []}
                renderRow={(product: any) => (
                    <>
                        <td className='font-bold'>{product.name}</td>
                        <td className=' text-darkColor'>{product.description}</td>
                        <td className='flex gap-5'>
                            <div className='w-16 h-16'>
                                <img className='w-full' src={`${API_URL}/imgs/${product.image}`} alt="brand-img" />
                            </div>

                        </td>
                        <td>
                            <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedProduct(product);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                                <button onClick={() => {
                                    setSelectedProduct(product);
                                    setShowdeletePopup(true)
                                }
                                } className='bg-red-600 rounded-lg py-1 px-3 text-white'>{t("delete")}</button>
                            </div>
                        </td>
                    </>
                )}
            />
            <div className='grid py-8'>
                <AddButton setShowAddForm={setShowAddForm} />
            </div>
            {showdeletePopup && <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={"ProductManagement/Delete"} id={selectedProduct!.id} />}

            {showAddForm && <AddForm
                setShowAddForm={setShowAddForm}
                fields={[
                    { name: 'name', type: 'text', label: t('product-name') },
                    { name: 'description', type: 'textarea', label: t('details') },
                    { name: 'image', type: 'file', label: t('brands') }
                ]}
                onSubmit={handleAddProduct}
            />}
            {showEditForm && selectedProduct && <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'name', type: 'text', label: t('product-name') },
                    { name: 'description', type: 'textarea', label: t('details') },
                    { name: 'image', type: 'file', label: t('brands') }
                ]}
                initialData={selectedProduct}
                onSubmit={handleEditProduct}
            />}
        </div>
    );
};

export default Products;
