import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Table from '../../components/common/Table';
import useFetch from '../../custom hooks/UseFetch';
import { fetchBrands, handleAddItem, handleUpdateItem } from '../../utils/data';
import DeletePopUp from '../../components/dashbord/DeletePopUp';
import Loading from '../../components/ui/Loading';
import AddForm from '../../components/dashbord/AddForm';
import EditForm from '../../components/dashbord/EditForm';
import AddButton from '../../components/ui/AddButton';
import DashbordTitle from '../../components/ui/DashbordTitle';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';

import {API_URL} from '../../utils/data';

const Brands = () => {
    const { t, i18n } = useTranslation();
    const [selectedBrand, setSelectedBrand] = useState<any>(null);
    const { showAddForm, showEditForm, showdeletePopup, setShowAddForm, setShowEditForm, setShowdeletePopup } = useStateContext() as GlobalStateContext;

    const { data: brandData, loading } = useFetch<any>(fetchBrands);
    const brands = brandData?.data
    const handleAdd = (data: any) => {
        handleAddItem(i18n.language, "BrandsManagement", data)
    };

    const handleEdit = (data: any) => {
        if (selectedBrand) {
            handleUpdateItem(i18n.language, data, "BrandsManagement")
        }

    };
    if (loading) return <Loading />
    return (
        <div className='bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={t("control-brand")} add={true}/>
            <Table
                headers={[t("product-name"), t("details"), t("brands"), t("actions")]}
                data={brands || []}
                renderRow={(brand: any) => (
                    <>
                        <td className='font-bold'>{brand.name}</td>
                        <td className='text-darkColor'>{brand.description}</td>
                        <td className='flex gap-5'>
                            <div className='w-16 h-16'>
                                <img className='w-full' src={`${API_URL}/imgs/${brand.image}`} alt="brand-img" />
                            </div>
                        </td>
                        <td>
                            <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedBrand(brand);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                                <button onClick={() => {
                                    setSelectedBrand(brand);
                                    setShowdeletePopup(true)
                                }
                                } className='bg-red-600 rounded-lg py-1 px-3 text-white'>{t("delete")}</button>
                            </div>
                        </td>
                    </>
                )}
            />
            <div className='grid py-8'>
                <AddButton setShowAddForm={setShowAddForm} />
            </div>
            {showdeletePopup && <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={"BrandsManagement/Delete"} id={selectedBrand?.id} />}
            {showAddForm && <AddForm
                setShowAddForm={setShowAddForm}
                fields={[
                    { name: 'name', type: 'text', label: t("brands") },
                    { name: 'description', type: 'textarea', label: t('details') },
                    { name: 'image', type: 'file', label: t('image') }
                ]}
                onSubmit={handleAdd}
            />}
            {showEditForm && selectedBrand && <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'name', type: 'text', label: t("brands") },
                    { name: 'description', type: 'textarea', label: t('details') },
                    { name: 'image', type: 'file', label: t('image') }
                ]}
                initialData={selectedBrand}
                onSubmit={handleEdit}
            />}
        </div>
    );
}
export default Brands