import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import { fetchGoals, handleUpdateItem } from '../../utils/data';
import Table from '../../components/common/Table';
import Loading from '../../components/ui/Loading';
import EditForm from '../../components/dashbord/EditForm';
import DashbordTitle from '../../components/ui/DashbordTitle';

const Goals = () => {
    const { t,i18n } = useTranslation();
    const [showEditForm, setShowEditForm] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState<any>(null);
    const { data: goalsData ,loading} = useFetch<any>(fetchGoals);
    const goals=goalsData?.data


    const handleEdit = (data: any) => {
        if(selectedGoal){
            handleUpdateItem(i18n.language,data,"HomePageManagement/Update/PagesSectionProperty")
        }

    };
    if(loading) return <Loading/>
    return (
        <div className='bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={t("control-goal")} add={false}/>

            <Table
                headers={[t("goal"),  t("actions")]}
                data={goals || []}
                renderRow={(goal: any) => (
                    <>
                        <td className=' font-bold'>{goal.content}</td>
                        <td>
                        <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedGoal(goal);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                            </div>
                        </td>
                    </>
                )}
            />
            {showEditForm && selectedGoal&& <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'content', type: 'text', label: t("goal") }
                ]}
                initialData={selectedGoal}
                onSubmit={handleEdit}
            />}
        </div>
    );
}

export default Goals