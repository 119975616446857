import React from 'react'
import Home from '../components/Brands/Home'

const BrandsLayout = () => {
  return (
    <div>
        <Home/>
    </div>
  )
}

export default BrandsLayout