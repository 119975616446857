import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdOutlineDomainVerification } from 'react-icons/md';
import { dashbordLinks, dashbordLinks_en } from '../../constant';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import { getPages, handleLogout } from '../../utils/data';
interface SidebarProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const Sidebar = ({ setOpen }: SidebarProps) => {
    const { t, i18n } = useTranslation();
    const { showAddForm, showEditForm, showdeletePopup } = useStateContext() as GlobalStateContext;
    const links = i18n.language === "ar" ? dashbordLinks : dashbordLinks_en;
    const [showMainLink, setShowMainLink] = useState(false);
    const isDisabled = showAddForm || showEditForm || showdeletePopup;
    const [pages, setPages] = useState<any>(null);
    useEffect(() => {
        const getPagesData = () => {
            getPages("").then(response => {
                const pagesData = response.data
                setPages(pagesData)
            });
        }
        getPagesData();
    }, []);
    return (
        <div className='flex justify-between no-scroll h-full flex-col bg-[#191919]'>
            <div>
                <div className=' w-full  bg-black text-4xl font-bold px-6 py-8'>
                    <div>
                        <h1>{t("dashbord")}</h1>
                    </div>
                </div>
                <div className={`flex flex-col gap-t-4 ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}>
                    <ul className=' pt-8'>
                        {links.map((link, index) => (
                            <li key={index} >
                                <NavLink to={`/dashbord/${link.href}`} onClick={() => setOpen(false)} className={({ isActive }) =>
                                    `flex items-center py-3 px-6 gap-3 w-full text-xl text-weakColor ${isActive ? 'text-white px-2 border-s-8 border-secondry' : ''}`}>
                                    <p className=' text-secondry text-xl'>{link.icon}</p>
                                    <p>{link.name}</p>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <button onClick={() => setShowMainLink(!showMainLink)} className={`flex items-center py-3 px-6 gap-3 w-full text-xl text-weakColor ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}>
                        <p className=' text-secondry text-xl'><MdOutlineDomainVerification /></p>
                        <div className={`${showMainLink ? "text-white" : ""} flex gap-2 items-center`}>
                            <p>{t("content-management")}</p>
                            <p className=' text-lg'>{showMainLink ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
                        </div>
                    </button>
                    {showMainLink && pages &&
                        <ul className=' flex flex-col w-full text-lg text-weakColor px-14 gap-5'>
                            {pages.map((link: any, index: number) => (
                                <li key={index} className=' w-full'>
                                    <Link to={`content-manage/${link.id.value}?name=${link.name}`} className='w-full'>
                                        {link.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
                <div className='px-6 py-8'>
                    <button onClick={()=>handleLogout()} className='text-red-600 text-xl font-semibold'>{t("logout")}</button>
                </div>
            </div>
        </div>
    )
}

export default Sidebar