
import { FaProductHunt, FaRegAddressBook, FaRegNewspaper } from 'react-icons/fa';

import { TbBrandSupabase } from "react-icons/tb";
import { IoTelescopeOutline } from "react-icons/io5";
import { RiContactsLine } from "react-icons/ri";
import { AiOutlineTeam } from "react-icons/ai";
import { GiStairsGoal } from "react-icons/gi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlinePassword } from 'react-icons/md';


export const navlinks = [
    { name: "الرئيسية", href: "/" },
    { name: "حول", href: "/about" },
    { name: "المركز الاعلامي", href: "/news" },
    { name: "العلامات التجارية", href: "/brands" },
    { name: "تواصل معنا", href: "/contact-us" },
]
export const dashbordLinks = [
    { name: "المنتجات", href: "products" ,icon:<FaProductHunt />},
    { name: "العلامات التجارية", href: "brands" ,icon:<TbBrandSupabase />},
    { name: " التواصل", href: "contact" ,icon:<RiContactsLine />},
    { name: " الرؤية", href: "vision" ,icon:<IoTelescopeOutline />},
    { name: " معلومات الفريق ", href: "team" ,icon:<AiOutlineTeam />},
    { name: "الاهداف", href: "goals" ,icon:<GiStairsGoal />},
    { name: "العناوين", href: "address" ,icon:<FaRegAddressBook  />},
    { name: "الأخبار", href: "newsInfo", icon: <FaRegNewspaper/> },
    { name: "معلومات الموقع ", href: "siteInfo" ,icon:<IoIosInformationCircleOutline />},
    { name: "تغيير كلمة المرور", href: "change-password" ,icon:<MdOutlinePassword  />},
]

export const navlinks_en = [
    { name: "Home", href: "/" },
    { name: "About", href: "/about" },
    { name: "Media Center", href: "/news" },
    { name: "Brands", href: "/brands" },
    { name: "Contact Us", href: "/contact-us" },
]

export const dashbordLinks_en = [
    { name: "Products", href: "products", icon: <FaProductHunt /> },
    { name: "Brands", href: "brands", icon: <TbBrandSupabase /> },
    { name: "Contact", href: "contact", icon: <RiContactsLine /> },
    { name: "Vision", href: "vision", icon: <IoTelescopeOutline /> },
    { name: "Team Information", href: "team", icon: <AiOutlineTeam /> },
    { name: "Goals", href: "goals", icon: <GiStairsGoal /> },
    { name: "Addresses", href: "address", icon: <FaRegAddressBook /> },
    { name: "News", href: "newsInfo", icon: <FaRegNewspaper/> },
    { name: "Site Information", href: "siteInfo", icon: <IoIosInformationCircleOutline /> },
    { name: "Change Password", href: "change-password" ,icon:<MdOutlinePassword  />},
]
