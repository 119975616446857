
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        var lang = e.target.value;
        i18n.changeLanguage(lang);
        window.location.reload();
    };
    return (
        <label className='border-2 rounded'>
            <p className=' sr-only'>change language</p>
            <select
                defaultValue={i18n.language}
                className='bg-transparent xs:text-sm sm:text-base  focus:outline-none'
                onChange={onSelectChange}
            >
                <option className=' text-secondry' value='en'>English</option>
                <option className=' text-secondry' value='ar'>العربية</option>
            </select>
        </label>
    )
}

export default LanguageSwitcher