import { Link } from "react-router-dom"
import { LinkProps } from "../../types"

const MainLink = ({ title, href }: LinkProps) => {
    return (
        <div>
            <Link to={`${href}`}
            className=' text-white xs:text-xl sm:text-2xl bg-primary border-2 border-primary  px-4 py-2 rounded-full  transition-all duration-500 hover:border-secondry hover:bg-secondry'>
                {title}
            </Link>
        </div>
    )
}

export default MainLink