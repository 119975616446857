import React from 'react'
import { API_URL } from '../../utils/data'

const NewsPopUp = ({ setShowNews, blog }: any) => {
    return (
        <div className='fixed w-full  inset-0 z-50 bg-[#0000007e] flex-center '>
            <div className=' w-2/3 no-scroll bg-white overflow-y-auto p-10 brand-shadow rounded-md '>
                <div className=' relative p-6 rounded-md flex-center flex-col' >
                    <div className=' absolute top-2 left-2'>
                        <button 
                        onClick={() => setShowNews(false)} 
                        className=' font-bold text-white text-lg w-8 h-8 bg-red-500 rounded-full flex-center'>X</button>
                    </div>
                    <div className='w-60 h-60 overflow-hidden flex-center'>
                        <img className='w-full' src={`${API_URL}/imgs/${blog.image}`} alt="product-brand" />
                    </div>
                    <h2 className='text-2xl font-bold'>{blog.title}</h2>
                    <p className='text-lg  text-darkColor'>
                        {blog.description}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NewsPopUp