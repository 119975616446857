import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import { fetchvision, handleUpdateItem } from '../../utils/data';
import Table from '../../components/common/Table';
import Loading from '../../components/ui/Loading';
import EditForm from '../../components/dashbord/EditForm';
import DashbordTitle from '../../components/ui/DashbordTitle';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';

const Vision = () => {
    const { t,i18n } = useTranslation();
    const {showEditForm ,setShowEditForm} = useStateContext() as GlobalStateContext;

    const [selectedVision, setSelectedVision] = useState<any>(null);
    const { data: visionData, loading } = useFetch<any>(fetchvision);
    const vision=visionData?.data

    const handleEditVision = (data: any) => {
        if (selectedVision) {
            handleUpdateItem(i18n.language, data, "HomePageManagement/Update/PagesSectionProperty")
        }
    };
    if (loading) return <Loading />
    return (
        <div className='bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={t("control-vision")} add={false}/>
            <Table
                headers={[t("vision"), t("details"), t("actions")]}
                data={vision || []}
                renderRow={(visionItem: any) => (
                    <>
                        <td className='font-bold'>{visionItem.title}</td>
                        <td className=' text-darkColor'>{visionItem.content}</td>
                        <td>
                            <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedVision(visionItem);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                            </div>
                        </td>
                    </>
                )}
            />

            {showEditForm && selectedVision && <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'title', type: 'text', label: t("contact-way") },
                    { name: 'content', type: 'textarea', label: t('details') },
                ]}
                initialData={selectedVision}
                onSubmit={handleEditVision}
            />}
        </div>
    );
}

export default Vision