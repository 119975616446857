import React from 'react'
import Navbar from '../common/Navbar'
import MainLink from '../ui/MainLink';
import AltLink from '../ui/AltLink';
import Loading from '../ui/Loading';
import { API_URL } from '../../utils/data';

const Hero = ({heroData}:any) => {
    if (!heroData) {
        return <Loading/>;
}
    return (
        <section
        style={{
            backgroundImage: `url('${API_URL}/imgs/${heroData?.image}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
        className='hero h-screen xs:bg-center md:bg-top relative overflow-hidden'>
            <Navbar />
            <div className='h-full flex-center' id='hero'>
                <div className='overlay'></div>
                <div className=' h-full max-container padding-container text-center absolute w-full text-white flex-center flex-col z-20' >
                    <div className=' text-start w-full'>
                        <div>
                            <h1 data-aos="fade-left" className='xs:text-6xl md:text-8xl font-extrabold pb-5'>
                                {heroData.mainTitle.split('\r\n').map((line: string, index: number) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}</h1>
                            <p data-aos="fade-left" data-aos-delay="300" className='text-2xl pb-2'>{heroData.content}</p>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="500" className=' xs:flex-col sm:flex-row mt-10 w-fit flex gap-10'>
                            <MainLink title={heroData.linkTitle} href={heroData.link} />
                            <AltLink title={heroData.homePageSectionProperties[0]?.linkTitle} href={heroData.homePageSectionProperties[0]?.link} />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Hero