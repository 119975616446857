
import React from 'react'
import { useTranslation } from 'react-i18next';
const AddButton = ({ setShowAddForm }: any) => {

  const { t } = useTranslation();
  return (
    <button
      onClick={() => setShowAddForm(true)}
      className='flex-center text-nowrap gap-1 bg-blue-600 justify-self-end rounded-lg py-1 px-3 text-xl text-white'>
      {t("add")} +
    </button>
  )
}

export default AddButton