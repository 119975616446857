import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getData } from '../../utils/data';
import Hero from './Hero'
import Footer from '../common/Footer'
import WhoWe from './WhoWe'
import HitunTeam from './HitunTeam'
import Position from './Position'
import Vision from './Vision'
import Step from './Step'
import Loading from '../ui/Loading';
import toast from 'react-hot-toast';

const Index = () => {
  const { i18n } = useTranslation();
  const [heroData, setHeroData] = useState < any > (null);
  const [hitunTeam, setHitunTeam] = useState < any > (null);
  const [position, setPosition] = useState < any > (null);
  const [goals, setGoals] = useState < any > (null);
  const [vision, setVision] = useState < any > (null);
  const [aboutData, setAboutData] = useState < any > (null);
  useEffect(() => {
    const getHeroData = () => {
      getData(i18n.language).then(response => {
        console.log('home', response);
        if (!response.data) {
          toast.error("خطأ في تحميل الصفحة");
          return;
        }
        var aboutPageSelection = response.data.filter((item: any) => item.number == 2);
        if (aboutPageSelection.length === 0) {
          toast.error("خطأ في تحميل الصفحة");
          return;
        }
        var aboutPage = aboutPageSelection[0];
        const hero = aboutPage.homePageSections[0]
        const data = aboutPage.homePageSections[2];
        const positionData = aboutPage.homePageSections[3];
        const stepData = aboutPage.homePageSections[5];
        const visionData = aboutPage.homePageSections[4];
        const about = aboutPage.homePageSections[1];
        setHeroData(hero);
        setHitunTeam(data)
        setPosition(positionData)
        setGoals(stepData);
        setAboutData(about);
        setVision(visionData);
      });
    }
    getHeroData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!heroData) {
    return <Loading />;
  }
  return (
    <div>
      <Hero heroData={heroData} />
      <WhoWe aboutData={aboutData} />
      <HitunTeam hitunTeam={hitunTeam} />
      <Position position={position} />
      <Vision vision={vision} />
      <Step goals={goals} />
      <Footer />
    </div>
  )
}

export default Index