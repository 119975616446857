import { useTranslation } from 'react-i18next'
import { ImLocation2 } from "react-icons/im";
import Map from '../ui/Map';

const Location = () => {
    const { t } = useTranslation();
    return (
        <div className=' main-prop'>
            <div className=' max-container padding-container'>
                <div className='section-heading flex items-center gap-3 mb-10'>
                    <div className=' flex-center flex-col'>
                        <p className=' contact-icon text-primary text-2xl'><ImLocation2 /></p>
                        <div className='w-10'>
                            <img src="/asset/icon.webp" alt="icon" />
                        </div>
                    </div>
                    <div>
                        <h2 className='text-4xl font-semibold'>{t("map")}</h2>
                    </div>
                </div>
                <Map/>
            </div>
        </div>
    )
}

export default Location