import React from 'react';

interface TableProps<T> {
    headers: string[];
    data: T[];
    renderRow: (item: T) => React.ReactNode;
}

const Table = <T,>({ headers, data, renderRow }: TableProps<T>) => {
    return (
        <table className='w-full mt-5 bg-white'>
            <thead className='text-2xl font-semibold bg-primary border-b-[1px] py-2'>
                <tr className='xs:flex-between sm:table-row'>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index} className=' border-b-[1px] text-xl sm:table-row xs:flex xs:flex-col sm:flex-row border-gray-300'>
                        {renderRow(item)}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
