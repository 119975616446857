import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import DashbordLayout from '../dashbord/DashbordLayout';

const ProtectedRoute = () => {
  const isAuthenticated = !!Cookies.get("token");

  return isAuthenticated ? <DashbordLayout /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
