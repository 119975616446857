import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
import useFetch from '../../custom hooks/UseFetch';
import { API_URL, fetchBlog, handleAddItem, handleUpdateItem } from '../../utils/data';
import Loading from '../../components/ui/Loading';
import DashbordTitle from '../../components/ui/DashbordTitle';
import Table from '../../components/common/Table';
import AddButton from '../../components/ui/AddButton';
import DeletePopUp from '../../components/dashbord/DeletePopUp';
import AddForm from '../../components/dashbord/AddForm';
import EditForm from '../../components/dashbord/EditForm';

const NewsInfo = () => {
    const { t, i18n } = useTranslation();
    const { showAddForm, showEditForm, showdeletePopup, setShowAddForm, setShowEditForm, setShowdeletePopup } = useStateContext() as GlobalStateContext;
    const [selectedBlog, setSelectedBlog] = useState<any>(null);
    const { data: blogData,loading } = useFetch<any>(fetchBlog);
    const blogs = blogData?.data;

    const handleAddProduct = (data: any) => {
        handleAddItem(i18n.language, "Blog", data)
    };

    const handleEditProduct = (data: any) => {
        if (selectedBlog) {
            handleUpdateItem(i18n.language, data, "Blog")

        }

    };
    if (loading) return <Loading />
  return (
<div className='bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={t("control-product")} add={true} />
            <Table
                headers={[t("address"), t("details"), t("image"), t("actions")]}
                data={blogs || []}
                renderRow={(blog: any) => (
                    <>
                        <td className='font-bold'>{blog.title}</td>
                        <td className=' text-darkColor'>{blog.description}</td>
                        <td className='flex gap-5'>
                            <div className='w-16 h-16'>
                                <img className='w-full' src={`${API_URL}/imgs/${blog.image}`} alt="blog-img" />
                            </div>

                        </td>
                        <td>
                            <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedBlog(blog);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                                <button onClick={() => {
                                    setSelectedBlog(blog);
                                    setShowdeletePopup(true)
                                }
                                } className='bg-red-600 rounded-lg py-1 px-3 text-white'>{t("delete")}</button>
                            </div>
                        </td>
                    </>
                )}
            />
            <div className='grid py-8'>
                <AddButton setShowAddForm={setShowAddForm} />
            </div>
            {showdeletePopup && <DeletePopUp setShowdeletePopup={setShowdeletePopup} api={"Blog/Delete"} id={selectedBlog!.id} />}

            {showAddForm && <AddForm
                setShowAddForm={setShowAddForm}
                fields={[
                    { name: 'title', type: 'text', label: t('address') },
                    { name: 'description', type: 'textarea', label: t('details') },
                    { name: 'image', type: 'file', label: t('image') }
                ]}
                onSubmit={handleAddProduct}
            />}
            {showEditForm && selectedBlog && <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'title', type: 'text', label: t('address') },
                    { name: 'description', type: 'textarea', label: t('details') },
                    { name: 'image', type: 'file', label: t('image') }
                ]}
                initialData={selectedBlog}
                onSubmit={handleEditProduct}
            />}
        </div>
    );
};

export default NewsInfo