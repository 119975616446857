import toast from "react-hot-toast";
import Cookies from 'js-cookie';
import { Identidier } from '../types';
import i18n from '../i18n';
export const API_URL0 = 'https://localhost:7277';
export const API_URL = 'https://ghazala-api.prcsr.ly';

export const handleLogout = () => {

    Cookies.remove('token');
    Cookies.remove('rf-token');
    window.location.href = '/';
};
const fetchData = async (url: string) => {
    try {
        console.log('api', url);
        console.log('language', i18n.language);
        const response = await fetch(url, {
            headers: {
                'Accept-Language': i18n.language,
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getUserToken()}`,
            },
        });
        if (!response.ok) {
            // throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data.data)
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        // throw error;
    }
};

// Fetch all
export const fetchProducts = () => fetchData(`${API_URL}/ProductManagement`);
export const fetchBrands = () => fetchData(`${API_URL}/BrandsManagement`);
export const fetchSiteInfo = () => fetchData(`${API_URL}/SiteInfo`);
export const fetchContact = () => fetchData(`${API_URL}/HomePageManagement/PagesSectionProperty/08dcdaac-e904-44d4-8f5c-f68357f8d625`);
export const fetchvision = () => fetchData(`${API_URL}/HomePageManagement/PagesSectionProperty/08dcdaf7-cbb6-4f65-814d-5c7b0ac71baf`);
export const fetchTeam = () => fetchData(`${API_URL}/HomePageManagement/PagesSectionProperty/08dcdaf7-14d1-4302-8060-dd1185eba5d2`);
export const fetchGoals = () => fetchData(`${API_URL}/HomePageManagement/PagesSectionProperty/08dcdaf7-fb89-42d8-852b-3b98cfcca43b`);
export const fetchAddress = () => fetchData(`${API_URL}/AddressManagement`);
export const fetchBlog = () => fetchData(`${API_URL}/Blog`);

const getUserToken = (): string | undefined => Cookies.get('token');
const setUserToken = (token: string) => Cookies.set('token', token, { expires: 7 });
const setUserRefreshToken = (token: string) => Cookies.set('rf-token', token, { expires: 7 });
//--------------------------------------------------------------------
//
//
// Function to delete a item
export const handleDeleteItem = async (language: string, api: string, id: Identidier) => {
    try {
        console.log(id);
        const response = await fetch(`${API_URL}/${api}`, {
            method: 'Post',
            headers: {
                'Accept-Language': i18n.language,
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getUserToken()}`,
            },
            body: JSON.stringify(
                {
                    id: id
                }
            ),
        });

        if (response.status === 401) {
            handleLogout();
        }

        if (response.ok) {
            toast.success('تم حذف المنتج بنجاح');

            window.location.reload();

            response.body?.getReader()?.read().then((result) => {
                console.log(result);
            })
            console.log(response);

        } else {
            toast.error('حدث خطأ أثناء حذف المنتج');
        }
    } catch (error) {
        console.error('Error deleting product:', error);
        toast.error('حدث خطأ أثناء حذف المنتج');
    }
};
//-----------------------------------------------------------------------
// Function to add a new item
export const handleAddItem = async (language: string, api: string, newItem: any) => {
    try {
        const response = await fetch(`${API_URL}/${api}`, {
            method: 'POST',
            headers: {
                'Accept-Language': i18n.language,
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getUserToken()}`,
            },
            body: JSON.stringify(newItem),
        });


        if (response.status === 401) {
            handleLogout();
        }

        if (response.ok) {
            toast.success('تم إضافة المنتج بنجاح');
            window.location.reload();
        } else {
            toast.error('حدث خطأ أثناء إضافة المنتج');
        }


    } catch (error) {
        console.error('Error adding product:', error);
        toast.error('حدث خطأ أثناء إضافة المنتج');
    }
};
//-------------------------------------------------------------------
// Function to submit updated data
export const handleUpdateItem = async (language: string, editValues: any, api: string) => {
    try {
        const response = await fetch(`${API_URL}/${api}`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.language,
                'Authorization': `Bearer ${getUserToken()}`,
            },
            body: JSON.stringify(editValues),
        });

        handleResponse(response, false, () => {
            toast.success('تم التحديث  بنجاح');
            console.log(response);
            window.location.reload();
        }, (errMsg) => {
            toast.error(errMsg);
        });

    } catch (error) {
        console.error('Error updating product:', error);
        toast.error('حدث خطأ أثناء التحديث ');
    }
};
//-------------------------------------------------------------------
//
export const getData = async (language: string) => {
    try {
        const response = await fetch(`${API_URL}/HomePageManagement`, {
            method: 'GET',
            headers: {
                'Accept-Language': i18n.language,
                'Authorization': `Bearer ${getUserToken()}`,
            },
        });

        if (!response.ok) {
            // throw new Error(`Error: ${response.statusText}`);
        }
        if (response.status === 401) {
            handleLogout();
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was an error:', error);
    }
};
//--------------------------------------------
export const getPages = async (language: string) => {
    try {
        const response = await fetch(`${API_URL}/HomePageManagement/Pages`, {
            method: 'GET',
            headers: {
                'Accept-Language': i18n.language,
                'Authorization': `Bearer ${getUserToken()}`,
            },
        });

        if (!response.ok) {
            // throw new Error(`Error: ${response.statusText}`);
        }
        if (response.status === 401) {
            handleLogout();
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was an error:', error);
    }
};
//--------------------------------------------
export const getPagesContent = async (language: string, pageId: string) => {
    try {
        const response = await fetch(`${API_URL}/HomePageManagement/PagesSection/${pageId}`, {
            method: 'GET',
            headers: {
                'Accept-Language': i18n.language,
                'Authorization': `Bearer ${getUserToken()}`,
            },
        });

        if (!response.ok) {
            // throw new Error(`Error: ${response.statusText}`);
        }
        if (response.status === 401) {
            handleLogout();
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was an error:', error);
    }
};
//-------------------------------------------
export const handleUpdateSection = async (language: string, editValues: any) => {
    try {
        const response = await fetch(`${API_URL}/HomePageManagement/Update/PageSection`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.language,
                'Authorization': `Bearer ${getUserToken()}`,
            },
            body: JSON.stringify(editValues),
        });
        if (response.status === 401) {
            handleLogout();
        }
        if (response.ok) {
            toast.success('تم التحديث  بنجاح');
            window.location.reload();
        } else {
            toast.error('حدث خطأ أثناء التحديث ');
        }
    } catch (error) {
        console.error('Error updating product:', error);
        toast.error('حدث خطأ أثناء التحديث ');

    }
};
//-------------------------------------------
export const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append('ImageFile', file);
    formData.append('Category', "property");
    const response = await fetch(`${API_URL}/HomePageManagement/UploadImage`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${getUserToken()}`,
            'Accept-Language': i18n.language,
        },
        body: formData,
    });

    if (!response.ok) {
        // throw new Error('Image upload failed');
    }
    if (response.status === 401) {
        handleLogout();
    }
    const resultText = await response.text(); // Get response as text
    console.log(resultText);
    return resultText;
};
//----------------------------------------
// handle auth
export const handleAuth = async (requestData: any, successMessage: string, navigate: any) => {
    try {
        const response = await fetch(`${API_URL}/Authentication/signin`, {
            method: 'POST',
            headers: {
                //'Accept-Language': i18n.language,
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (data.status === false) {
            toast.error(`${data.errorMessage}`);
            return;
        }

        if (response.status === 401) {
            handleLogout();
        }

        toast.success(successMessage);
        setUserToken(data.data?.token);
        setUserRefreshToken(data.data?.refreshToken);
        navigate("/dashbord")
        return data;
    } catch (error) {
        console.error(`${error}: Failed to process `);
        return null;
    }
};
export const handlePasswordUpdate = async (language: string, editValues: any, api: string) => {
    try {
        const response = await fetch(`${API_URL}/${api}`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                // 'Accept-Language': i18n.language,
                'Authorization': `Bearer ${getUserToken()}`,
            },
            body: JSON.stringify({
                'phone': '',
                'password': editValues
            }),
        });

        handleResponse(response, false, () => {
            toast.success('تم التحديث  بنجاح');
            console.log(response);
            //window.location.reload();
        }, (errMsg) => {
            toast.error(errMsg);
        });
        if (response.status === 401) {
            handleLogout();
        }
    } catch (error) {
        console.error('Error updating product:', error);
        toast.error('حدث خطأ أثناء التحديث ');
    }
};
//-----------------------------------------

//  Helpers

const handleResponse = async (response: any, isArray: boolean = false, onSuccess?: () => void, onError?: (errMsg: string) => void) => {
    //debugger;
    if (response.status === 401) {
        handleLogout();
    }
    if (!response.ok) {
        toast.error('حدث خطأ');
    }
    const httpResponseWrpper = await response.json();
    // console.log(httpResponseWrpper.statusCode );
    switch (httpResponseWrpper.statusCode) {
        case 200:
            if (httpResponseWrpper.errorMessage)
                toast.success(httpResponseWrpper.errorMessage);
            onSuccess?.();
            break;
        case 401:
            handleLogout();
            break;
        case 500:
            console.log(httpResponseWrpper.errorMessage);
            //toast.error('حدث خطأ');
            onError?.(httpResponseWrpper.errorMessage);
            break;
    }
    if (isArray) {
    } else {
    }
    return httpResponseWrpper;
}

const baseApiRequest = async (api: string, method: 'GET' | 'POST', requestData: any = null, useLangauge: boolean = false, useAuth: boolean = false, onSuccess?: () => void) => {
    try {
        var header = [];
        var token = Cookies.get('token');
        if (useLangauge) {
            header.push({
                'Accept-Language': i18n.language,
            });
        }
        if (useAuth) {
            header.push({
                'Authorization': `Bearer ${getUserToken()}`,
            });
        }
        if (method === 'POST') {
            header.push({
                'Content-Type': 'application/json',
            });
        }
        const headers = header.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        const body = requestData == undefined ? '' : JSON.stringify(requestData);
        const response = await fetch(`${API_URL}/${api}`, {
            method: 'GET',
            headers,
            body
        });

        if (response.status === 401) {
            handleLogout();
            return;
        }
        if (!response.ok) {
            console.log(response);
            toast.error('حدث خطأ');
            return;
        }

        const data = await response.json();

        console.log(data);

        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        // // throw error;
    }
}