import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../custom hooks/UseFetch';
import { fetchTeam, handleUpdateItem } from '../../utils/data';
import Table from '../../components/common/Table';
import Loading from '../../components/ui/Loading';
import EditForm from '../../components/dashbord/EditForm';
import DashbordTitle from '../../components/ui/DashbordTitle';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import {API_URL} from '../../utils/data';

const Team = () => {
    const { t,i18n} = useTranslation();
    const [selectedMember, setSelectedMember] = useState<any>(null);
    const { data: teamData, loading } = useFetch<any>(fetchTeam);
    const team = teamData?.data
    const {showEditForm ,setShowEditForm} = useStateContext() as GlobalStateContext;


    const handleEdit = (data: any) => {
        if (selectedMember) {
            handleUpdateItem(i18n.language,data, "HomePageManagement/Update/PagesSectionProperty")
        }

    };
    if (loading) return <Loading />
    return (
        <div className='bg-gray-200 pb-8 px-6'>
            <DashbordTitle title={t("team")} add={false}/>

            <Table
                headers={[t("member-name"), t("position"), t("image"), t("actions")]}
                data={team || []}
                renderRow={(teamMember: any) => (
                    <>
                        <td className=' font-bold'>{teamMember.title}</td>
                        <td className=' text-darkColor'>{teamMember.content}</td>
                        <td>
                            <div className='w-14 h-14'>
                                <img className='w-full' src={`${API_URL}/imgs/${teamMember.image}`} alt="teamMember-img" />
                            </div>
                        </td>
                        <td>
                            <div className='flex gap-4'>
                                <button onClick={() => {
                                    setSelectedMember(teamMember);
                                    setShowEditForm(true);
                                }} className='bg-green-600 rounded-lg py-1 px-3 text-white'>
                                    {t("edit")}
                                </button>
                                </div>
                        </td>
                    </>
                )}
            />
            {showEditForm && selectedMember && <EditForm
                setShowEditForm={setShowEditForm}
                fields={[
                    { name: 'title', type: 'text', label: t("member-name") },
                    { name: 'content', type: 'textarea', label: t('position') },
                    { name: 'image', type: 'file', label: t("image") },
                ]}
                initialData={selectedMember}
                onSubmit={handleEdit}
            />}
        </div>
    );
}
export default Team