import React from 'react'
import { useTranslation } from 'react-i18next'
import { handleDeleteItem } from '../../utils/data';
import { Identidier } from '../../types';
import i18n from '../../i18n';
interface PopUpProps {
    setShowdeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    id: Identidier;
    api: string;
}
const DeletePopUp = ({ setShowdeletePopup, api, id }: PopUpProps) => {
    const { t } = useTranslation();
    return (
        <div className='fixed xs:w-full md:w-4/6 lg:w-4/5 inset-0 bg-[#000000e3] flex items-center justify-center '>
            <div className=' xs:w-72 sm:w-96 bg-white p-5'>
                <p className='text-xl font-bold mb-5'>{t("delete-text")}</p>
                <div className=' grid text-lg'>
                    <div className=' justify-self-end flex gap-5 '>
                        <button onClick={() => setShowdeletePopup(false)} className=' font-bold text-secondry' >{t("cancel")}</button>
                        |
                        <button onClick={() => {
                            handleDeleteItem(i18n.language, api, id)
                            setShowdeletePopup(false)
                        }}
                            className='font-bold text-rose-600'>
                            {t("delete")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeletePopUp